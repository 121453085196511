import {rmApp} from "../../clients/app";
import {axClient} from "@/clients/axios";

rmApp.createApp({
    methods: {
        scrollMe: function(anchor) {
            let vm = this;
            let scrollTo = null
            switch (anchor) {
                case "unternehmenswerte":
                    scrollTo = vm.$refs.unternehmenswerte
                    break
                case "beteiligungen":
                    scrollTo = vm.$refs.beteiligungen
                    break
                case "meilensteine":
                    scrollTo = vm.$refs.meilensteine
                    break
            }
            if (scrollTo) {
                vm.$smoothScroll({
                    scrollTo: scrollTo,
                    duration: 500,
                    offset: 0,
                    updateHistory: false
                });
            }
        }
    },
    mounted: function() {
        let vm = this;
        setTimeout(function () {
            if (location.hash) {
                let scrollTo = null
                switch (location.hash) {
                    case "#unternehmenswerte":
                        scrollTo = vm.$refs.unternehmenswerte
                        break
                    case "#beteiligungen":
                        scrollTo = vm.$refs.beteiligungen
                        break
                    case "#meilensteine":
                        scrollTo = vm.$refs.meilensteine
                        break
                }
                if (scrollTo) {
                    vm.$smoothScroll({
                        scrollTo: scrollTo,
                        duration: 500,
                        offset: 0,
                        updateHistory: false
                    });
                }
            }
        }, 300)

        axClient.apiGet("/timeline", function(resp) {
            let data = resp.data;
            vm.events.splice(0, vm.events.length);
            data?.forEach((item) => {
                vm.events.push(item);
            });
        })
    }
});